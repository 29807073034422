
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import SingleImageUpload from '@/component/single-image-upload.vue';
import { PaginationData } from '@/api/operation/customer';
import { getValidatorMap, Validate } from '@/mixin/validator';
import {
  addPosition,
  Category,
  initCategory,
  getCategoryData,
  Zone,
  initZoneData,
  getZoneWare,
  WareData,
  initWareData,
  wareData,
  getAdvPositionDetail,
  editPosition,
} from '@/api/operation/advertising-position';
import { useGlobalFunction } from '@/pinia/modules/globalFunction';
// 上传文件的路径
const UPLOAD_DIR = {
  advertising_position_image: 'operation/advertising_position_image',
};
interface Form {
  ware_category_id: number | string;
  img: string;
  jump_mode: number;
  jump_type: number;
  zone: any[];
  product: any[];
  zone_title: string;
}
function initForm(remote?: any): Form {
  remote = remote || {};
  return {
    ware_category_id: remote.category_id || '',
    img: remote.image_url || '',
    jump_mode: remote.skip_type || 6,
    jump_type: 1,
    zone: [],
    product: [],
    zone_title: '',
  };
}
const STATUS = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '上架中',
  },
  {
    id: 2,
    value: '已下架',
  },
];
@Component({
  components: {
    SingleImageUpload,
  },
})
export default class AddAdvertisingPosition extends Mixins(Validate) {
  globalFunction= useGlobalFunction().globalFunction;  //更新数据
  category_data: Category[] = initCategory();
  zone_data: PaginationData<Zone> = initZoneData(); // 专区数据
  ware_data: PaginationData<WareData> = initWareData(); // 商品数据
  is_edit: boolean = false;
  is_loading: boolean = false;
  is_show_special_area: boolean = false;
  is_show_ware_detail_modal: boolean = false;
  form_item_layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  selectedZoneRowKeys: any[] = [];
  selectedWareRowKeys: any[] = [];
  ware_modal_filter_data: any = {
    ware_id: '',
    category: [],
    ware_name: '',
    status: 0,
  };
  form: Form = initForm();
  preview_image: string = '';
  preview_visible: boolean = false;
  validator_list: any = [
    {
      field: 'ware_category_id',
      message: '请选择分类',
    },
    {
      field: 'img',
      message: '请上传广告图',
    },
  ];
  validatorMap: { [field: string]: Validator } = getValidatorMap(this.validator_list);
  get UPLOAD_DIR(): any {
    return UPLOAD_DIR;
  }
  get status() {
    return STATUS;
  }
  beforeCommonUpload(file: any) {
    if (file.size / 1024 / 1024 > 2) {
      this.$message.error('上传图片大小不能超过2M');
      return false;
    }
  }
  // 显示模态窗，改变模态窗中的图片路径
  handlePreview(imageUrl: string) {
    this.preview_image = imageUrl;
    this.preview_visible = true;
  }

  @Watch('ware_data', { deep: true })
  addWareParentCategory() {
    this.ware_data.detail.forEach((ware: any) => {
      this.category_data.forEach((category: any) => {
        if (ware.category.parent_id === category.id && ware.category.parent_id !== 0) {
          ware.category.parent_name = `${category.name}/`;
        }
      });
    });
  }

  // 勾选特色专区时
  onSelectZoneChange(selectedRowKeys: any) {
    this.selectedZoneRowKeys = selectedRowKeys;
  }
  // 勾选特色专区时
  onSelectWareChange(selectedRowKeys: any) {
    this.selectedWareRowKeys = selectedRowKeys;
  }
  paginationOptions(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  // 添加特色专区
  @changeLoading(['is_loading'])
  async addZone() {
    this.form.zone = [];
    this.form.zone_title = '';
    this.is_show_special_area = false;
    this.zone_data.detail.forEach((item: any) => {
      if (item.id === this.selectedZoneRowKeys[0]) {
        this.form.zone.push(item);
      }
    });
    await this.getZoneWare();
  }
  // 添加商品
  @changeLoading(['is_loading'])
  async addWare() {
    this.form.product = [];
    this.is_show_ware_detail_modal = false;
    this.ware_data.detail.forEach((item: any) => {
      if (item.id === this.selectedWareRowKeys[0]) {
        this.form.product.push(item);
      }
    });
    this.form.product[0].banner_url_list = this.form.product[0].viewpager_url.split(',');
    this.ware_modal_filter_data = {
      ware_id: '',
      category: [],
      ware_name: '',
      status: 0,
    };
    await this.getWareData();
    
  }
  // 关闭模态窗
  @changeLoading(['is_loading'])
  async closeModal(type: number) {
    // type 1为特色专区模态窗 2为商品模态窗
    if (type === 1) {
      this.is_show_special_area = false;
      this.form.zone_title = '';
      await this.getZoneWare();
    }
    if (type === 2) {
      this.is_show_ware_detail_modal = false;
      this.ware_modal_filter_data = {
        ware_id: '',
        category: [],
        ware_name: '',
        status: 0,
      };
      await this.getWareData();
    }
  }
  @changeLoading(['is_loading'])
  async handleChange(data: any, type: number) {
    // type   1为特色专区 2为商品
    console.log(data);
    if (type === 1) {
      await this.getZoneWare(data.current, this.form.zone_title);
    }
    if (type === 2) {
      await this.clickSearchBtn(data.current);
    }
  }

  // 切换跳转方式
  changeJumpMode() {
    this.form.jump_type = 1;
    this.form.product = [];
    this.form.zone = [];
  }
  // 切换跳转类型
  changeJumpType() {
    this.form.product = [];
    this.form.zone = [];
  }

  // 点击搜索按钮
  @changeLoading(['is_loading'])
  async clickSearchBtn(current?: any) {
    const query_params = {
      product_id: this.ware_modal_filter_data.ware_id,
      product_name: this.ware_modal_filter_data.ware_name,
      category_id: this.ware_modal_filter_data.category[
        this.ware_modal_filter_data.category.length - 1
      ],
      is_show: this.ware_modal_filter_data.status === 0 ? '' : this.ware_modal_filter_data.status,
      current: 1,
      page: 5,
    };
    
    query_params.product_id == '' ? delete query_params.product_id :query_params.product_id
    query_params.product_name== ''? delete query_params.product_name: query_params.product_name
    query_params.category_id==undefined ? delete query_params.category_id : query_params.category_id
    query_params.is_show == '' ? delete query_params.is_show:query_params.is_show
    //query_params.current.length<=0 ? delete query_params.current:query_params.current.length
    await this.getWareData(query_params);
  }

  // 搜索特色专区
  @changeLoading(['is_loading'])
  async saerchZone() {
    await this.getZoneWare(1, this.form.zone_title);
  }

  // 获取分类信息
  @changeLoading(['is_loading'])
  async getCategory() {
    const res = await getCategoryData();
    this.category_data = initCategory();
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.category_data = initCategory(res.data);
  }
  // 获取专区列表
  @changeLoading(['is_loading'])
  async getZoneWare(current?: number, title?: string, limit: number = 5) {
    const res: any = await getZoneWare(current, title, limit);
    this.zone_data = initZoneData();
    if (res.status !== 200) {
      this.$message.error(res.message);
      return;
    }
    this.zone_data = initZoneData(res.data);
  }
  // 获取商品列表
  @changeLoading(['is_loading'])
  async getWareData(query_params: any = { current: 1, page: 5 }) {
    const res: any = await wareData(query_params);
    this.ware_data = initWareData();
    if (res.status !== 200) {
      //this.$message.error(res.message);
      return;
    }
    this.ware_data = initWareData(res.data);
  }
  // 提交
  @changeLoading(['is_loading'])
  async submit() {
    if (!this.validateCommit()) {
      return;
    }
    if (this.form.jump_mode === 2 && this.form.jump_type === 1 && this.form.zone.length === 0) {
      this.$message.error('请选择特色专区');
      return;
    }
    if (this.form.jump_mode === 2 && this.form.jump_type === 2 && this.form.product.length === 0) {
      this.$message.error('请选择商品');
      return;
    }
    let category_name: any;
    this.category_data.forEach((item: any) => {
      if (item.id === this.form.ware_category_id) {
        category_name = item.name;
      }
    });
    const send_data = {
      category_id: this.form.ware_category_id,
      category_name: category_name,
      image_url: this.form.img,
      skip_type: this.form.jump_mode,
      zone_id: this.form.zone[0] ? this.form.zone[0].id : '',
      product_id: this.form.product[0] ? this.form.product[0].id : '',
      is_promotion: this.form.jump_type === 3 ? 1 : 0,
      jump_type: this.form.jump_mode === 2 ? this.form.jump_type :this.form.jump_mode,
      remark: '',
    };
    switch(send_data.jump_type){
      case 1:
        send_data.remark = this.form.zone[0] ? this.form.zone[0].id : 0
        break;
      case 2:
        send_data.remark = this.form.product[0] ? this.form.product[0].id : 0
        break
    }
    let res;
    if (this.is_edit) {
      res = await editPosition(send_data, +this.$route.query.id);
    } else {
      res = await addPosition(send_data);
    }

    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }

    this.$message.success('新增成功');

    this.$router.push({
      path:'/operation/advertising-position',
      query: { page: this.$route.query?.page || '1' },  
    });
    this.globalFunction.getAdvPosition() //更新数据
    // this.$router.go(0);
    // setTimeout(function () {window.location.reload();}, 1);  
    //this.$forceUpdate(); //强制刷新
    
  }

  // 获取详情
  @changeLoading(['is_loading'])
  async getAdvPositionDetail(id: number) {
    const res = await getAdvPositionDetail(id);
    if (res.status !== 200) {
      this.$message.error((res as any).message);
      return;
    }
    this.form = initForm(res.data);
    if (res.data.zone_id) {
      this.zone_data.detail.forEach((item: any) => {
        if (item.id === res.data.zone_id) {
          this.form.zone.push(item);
        }
      });
      this.selectedZoneRowKeys.push(this.form.zone[0].id);
    }
    if (res.data.product_id) {
      this.ware_data.detail.forEach((item: any) => {
        if (item.id === res.data.product_id) {
          this.form.product.push(item);
        }
      });
      if(this.form.product.length != 0){
        this.selectedWareRowKeys.push(this.form.product[0]?.id);
        this.form.product[0].banner_url_list = this.form.product[0]?.viewpager_url?.split(',');
      }
    }
    this.form.jump_type = res.data.is_promotion === 1 ? 3 : res.data.zone_id ? 1 : 2;
  }
  @changeLoading(['is_loading'])
  async created() {
    await this.getCategory();
    await this.getWareData();
    await this.getZoneWare();
    this.is_edit = !!this.$route.query.id;
    if (this.is_edit) {
      await this.getAdvPositionDetail(+this.$route.query.id);
    }
  }
}
